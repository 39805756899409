import { routeType } from "@prisma/client";
import type { StatusType } from "../server/resolvers/departureStatusResolver";
import { defaultConfig } from "@/scripts/settings";

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
  DISABLED = "DISABLED",
}
type simpleKeys = [
  "home",
  "title",
  "search",
  "stop",
  "adress",
  "departureStop",
  "arrivalStop",
  "time",
  "minimumTransferTime",
  "pageNotFound",
  "tripNotFound",
  "stopNotFound",
  "favouriteStops",
  "fillAllFields",
  "noRoutesFound",
  "tryDisablingFilters",
  "disabledMap",
  "mapLoadError",
  "settings",
  "description",
  "noInformation",
  "loadingMap",
  "departure",
  "arrival",
  "booleans",
  "textboxes",
  "numbers",
  "selects",
  "source",
  "reset",
  "settings_reload",
  "otherTrips",
  "graphql",
  "extraOptions",
  "inVehicleTransfer",
];
export type language = Record<simpleKeys[number], string> & {
  routeTypes: Record<routeType, string>;
  tripTitle: ({
    route_type,
    route_short_name,
    trip_headsign,
  }: {
    route_type: keyof typeof routeType;
    route_short_name: string;
    trip_headsign: string;
  }) => string;
  receivedOn: (time: number | null | undefined) => string;
  stopTitle: ({ stop_name }: { stop_name: string }) => string;
  plannerTitle: ({
    fromStopName,
    toStopName,
  }: {
    fromStopName: string;
    toStopName: string;
  }) => string;
  statusText: { [key in keyof typeof StatusType]: (stop?: string) => string };
  connectionStatus: Record<ConnectionStatus, string>;
  settingItems: {
    [key in keyof typeof defaultConfig]: string;
  };
  wrongPlatformMightBe: (platform: string) => string;
};
const nl: language = {
  routeTypes: {
    TRAM: "Tram",
    SUBWAY: "Metro",
    TRAIN: "Trein",
    BUS: "Bus",
    FERRY: "Veerpont",
    FUNICULAR: "Funicular",
  },
  home: "Home",
  title: "Reisplanner",
  search: "Zoeken",
  stop: "Halte",
  adress: "Adres",
  departureStop: "Vertrekhalte",
  arrivalStop: "Aankomsthalte",
  time: "Tijd",
  minimumTransferTime: "Minimale overstaptijd",
  pageNotFound: "Pagina niet gevonden",
  tripNotFound: "Rit niet gevonden",
  stopNotFound: "Halte niet gevonden",
  favouriteStops: "Favoriete haltes",
  fillAllFields: "Vul alle velden in",
  noRoutesFound: "Geen routes gevonden",
  tryDisablingFilters: "Geen routes gevonden. Probeer filters uit te zetten",
  disabledMap: "Kaart is uitgeschakeld",
  mapLoadError: "Kaart laden mislukt",
  settings: "Instellingen",
  description: "Reisplanner voor het openbaar vervoer in Nederland",
  tripTitle: ({ route_type, route_short_name, trip_headsign }) =>
    `${nl.routeTypes[route_type]} ${route_short_name} naar ${trip_headsign}`,
  receivedOn: (time) =>
    time
      ? `Ontvangen op ${new Date(time * 1000).toLocaleString()}`
      : "Geen data ontvangen",
  stopTitle: ({ stop_name }) => `Vertrektijden van ${stop_name}`,
  plannerTitle: ({ fromStopName, toStopName }) =>
    `Reis van ${fromStopName} naar ${toStopName}`,
  statusText: {
    FARAWAY: (stop?: string) => (stop ? `Ver weg van ${stop}` : "Ver weg"),
    ONROUTE: (stop?: string) => (stop ? `Onderweg naar ${stop}` : "Onderweg"),
    ARRIVAL: (stop?: string) =>
      stop ? `Bijna bij ${stop}` : `Bijna bij halte`,
    ONSTOP: (stop?: string) => (stop ? `Aangekomen op ${stop}` : "Aangekomen"),
    DEPARTURE: (stop?: string) =>
      stop ? `Vertrokken van ${stop}` : "Vertrokken",
  },
  noInformation: "Geen informatie",
  loadingMap: "Kaart laden",
  departure: "Vertrek",
  arrival: "Aankomst",
  connectionStatus: {
    CONNECTED: "Verbonden",
    CONNECTING: "Verbinden...",
    DISCONNECTED: "Verbinding verbroken",
    DISABLED: "",
  },
  settingItems: {
    syncBetweenTabs: "Sync settings tussen tabbladen",
    showReceivedTime: "Ontvangen tijd weergeven",
    showOtherTrips: "Andere ritten van voertuigen weergeven",
    defaultMinimumTransferTime: "Standaard minimale overstaptijd",
    subscriptionsEnabled: "Live data",
    darkMode: "Donkere modus",
    hideStopLabels: "Halte labels verbergen",
    labelFontSize: "Label lettergrootte",
    mapStyle: "Kaartstijl",
    language: "Taal",
  },
  booleans: "Ja/Nee",
  textboxes: "Tekst",
  numbers: "Nummer",
  selects: "Selecteervakjes",
  source: "Broncode",
  reset: "Reset",
  settings_reload: "Opslaan",
  otherTrips: "Andere ritten van voertuigen:",
  graphql: "GraphQL Schema",
  extraOptions: "Meer overstappen(traag!)",
  wrongPlatformMightBe: (platform: string) =>
    `Mogelijk verkeerde spoor, kan ${platform} zijn`,
  inVehicleTransfer: "blijven zitten",
};

const en: language = {
  routeTypes: {
    TRAM: "Tram",
    SUBWAY: "Metro",
    TRAIN: "Train",
    BUS: "Bus",
    FERRY: "Ferry",
    FUNICULAR: "Funicular",
  },
  home: "Home",
  title: "Journey planner",
  search: "Search",
  stop: "Stop",
  adress: "Address",
  departureStop: "Departure stop",
  arrivalStop: "Arrival stop",
  time: "Time",
  minimumTransferTime: "Minimum transfer time",
  pageNotFound: "Page not found",
  tripNotFound: "Trip not found",
  stopNotFound: "Stop not found",
  favouriteStops: "Favourite stops",
  fillAllFields: "Fill all fields",
  noRoutesFound: "No routes found",
  tryDisablingFilters: "No routes found. Try disabling filters",
  disabledMap: "Map is disabled",
  mapLoadError: "Map load failed",
  settings: "Settings",
  description: "Journey planner for public transport in the Netherlands",
  tripTitle: ({ route_type, route_short_name, trip_headsign }) =>
    `${en.routeTypes[route_type]} ${route_short_name} to ${trip_headsign}`,
  receivedOn: (time) =>
    time
      ? `Received on ${new Date(time * 1000).toLocaleString()}`
      : "No data received",
  stopTitle: ({ stop_name }) => `Departures from ${stop_name}`,
  plannerTitle: ({ fromStopName, toStopName }) =>
    `Journey from ${fromStopName} to ${toStopName}`,
  statusText: {
    FARAWAY: (stop?: string) => (stop ? `Far away from ${stop}` : "Far away"),
    ONROUTE: (stop?: string) => (stop ? `On the way to ${stop}` : "On the way"),
    ARRIVAL: (stop?: string) => (stop ? `Almost at ${stop}` : `Almost at stop`),
    ONSTOP: (stop?: string) => (stop ? `Arrived at ${stop}` : "Arrived"),
    DEPARTURE: (stop?: string) => (stop ? `Departed from ${stop}` : "Departed"),
  },
  noInformation: "No information",
  loadingMap: "Loading map",
  departure: "Departure",
  arrival: "Arrival",
  connectionStatus: {
    CONNECTED: "Connected",
    CONNECTING: "Connecting...",
    DISCONNECTED: "Connection lost",
    DISABLED: "",
  },
  settingItems: {
    syncBetweenTabs: "Sync settings between tabs",
    showReceivedTime: "show received time",
    showOtherTrips: "Show other trips of vehicles",
    defaultMinimumTransferTime: "Default minimum transfer time",
    subscriptionsEnabled: "Live data",
    darkMode: "Dark mode",
    hideStopLabels: "Hide stop labels",
    labelFontSize: "Label font size",
    mapStyle: "Map style",
    language: "Language",
  },
  booleans: "Yes/No",
  textboxes: "Text",
  numbers: "Number",
  selects: "Select boxes",
  source: "Source code",
  reset: "Reset",
  settings_reload: "Apply",
  otherTrips: "Other trips of vehicles:",
  graphql: "GraphQL Schema",
  extraOptions: "More transfers(slow!)",
  wrongPlatformMightBe: (platform: string) =>
    `Possible wrong platform, might be ${platform}`,
  inVehicleTransfer: "can stay in vehicle",
};

const empty: language = {
  routeTypes: {
    TRAM: "",
    SUBWAY: "",
    TRAIN: "",
    BUS: "",
    FERRY: "",
    FUNICULAR: "",
  },
  home: "",
  title: "",
  search: "",
  stop: "",
  adress: "",
  departureStop: "",
  arrivalStop: "",
  time: "",
  minimumTransferTime: "",
  pageNotFound: "",
  tripNotFound: "",
  stopNotFound: "",
  favouriteStops: "",
  fillAllFields: "",
  noRoutesFound: "",
  tryDisablingFilters: "",
  disabledMap: "",
  mapLoadError: "",
  settings: "",
  description: "",
  tripTitle: () => "",
  stopTitle: () => "",
  receivedOn: () => "",
  plannerTitle: () => "",
  statusText: {
    FARAWAY: () => ``,
    ONROUTE: () => ``,
    ARRIVAL: () => ``,
    ONSTOP: () => ``,
    DEPARTURE: () => ``,
  },
  noInformation: "",
  loadingMap: "",
  departure: "",
  arrival: "",
  connectionStatus: {
    CONNECTED: "",
    CONNECTING: "",
    DISCONNECTED: "",
    DISABLED: "",
  },
  settingItems: {
    syncBetweenTabs: "",
    showReceivedTime: "",
    showOtherTrips: "",
    defaultMinimumTransferTime: "",
    subscriptionsEnabled: "",
    darkMode: "",
    hideStopLabels: "",
    labelFontSize: "",
    mapStyle: "",
    language: "",
  },
  booleans: "",
  textboxes: "",
  numbers: "",
  selects: "",
  source: "",
  reset: "",
  settings_reload: "",
  otherTrips: "",
  graphql: "",
  extraOptions: "",
  wrongPlatformMightBe: () => "",
  inVehicleTransfer: "",
};

const languages = { nl, en, empty };
export default languages;
