import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";

const persistedLink = createPersistedQueryLink({ sha256 });
const httpLink = new HttpLink({
  uri: "/graphql",
});
const batchedHttpLink = new BatchHttpLink({
  uri: "/graphql",
  batchDebounce: true,
  batchMax: 1000,
});
// Create the subscription websocket link
export const wsLink = new GraphQLWsLink(
  createClient({
    retryAttempts: Infinity,
    shouldRetry: () => true,
    url:
      window.location.protocol.toString().replace("http", "ws") +
      "//" +
      window.location.host +
      "/graphql",
  }),
);
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return ["CalculateRoute"].includes(definition.name!.value);
    },
    httpLink,
    batchedHttpLink,
  ),
);

const link = persistedLink.concat(splitLink);
const cache = new InMemoryCache({
  typePolicies: {
    Stop: { keyFields: ["stop_id"] },
    Route: { keyFields: ["route_id"] },
    Trip: { keyFields: ["trip_id"] },
  },
});
// Create the apollo client
export default new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});
