import { reactive } from "vue";
import { objectEntries, objectKeys } from "../../utils/tsfixes";
import languages from "../../utils/translations";
import { mapStyles } from "@/scripts/mapSettings";

interface GlobalSettings {
  read(): typeof defaultConfig;
  write(settings: typeof defaultConfig): void;
}

const navigatorLanguage = window.navigator.language.split("-")[0];
const defaultLanguage: keyof typeof languages =
  navigatorLanguage in languages
    ? (navigatorLanguage as keyof typeof languages)
    : "en";

export const defaultConfig: {
  syncBetweenTabs: boolean;
  showReceivedTime: boolean;
  showOtherTrips: boolean;
  defaultMinimumTransferTime: number;
  darkMode: boolean;
  hideStopLabels: boolean;
  labelFontSize: number;
  subscriptionsEnabled: boolean;
  mapStyle: (typeof listOptions.mapStyle)[number];
  language: (typeof listOptions.language)[number];
} = {
  syncBetweenTabs: false,
  showReceivedTime: false,
  showOtherTrips: false,
  defaultMinimumTransferTime: 3,
  darkMode: true,
  hideStopLabels: false,
  labelFontSize: 2,
  subscriptionsEnabled: true,
  mapStyle: "simple",
  language: defaultLanguage,
} as const;
export const listOptions = {
  mapStyle: objectKeys(mapStyles),
  language: objectKeys(languages),
} as const;

const SETTINGS_KEY = "settings";
const globalSettings: GlobalSettings = {
  read() {
    return JSON.parse(
      window.localStorage.getItem(SETTINGS_KEY) ||
        JSON.stringify(defaultConfig),
    );
  },
  write(settings) {
    window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
  },
};

export const settings: typeof defaultConfig = reactive(globalSettings.read());

objectKeys(defaultConfig).forEach((key) => {
  //@ts-expect-error Migrations for old versions. This is safe.
  if (settings[key] === undefined) settings[key] = defaultConfig[key];
});
objectKeys(settings).forEach((key) => {
  if (defaultConfig[key] === undefined) delete settings[key];
});

let connection: BroadcastChannel | null = null;
if (settings.syncBetweenTabs) {
  connection = new BroadcastChannel("settings");
  connection.onmessage = ({ data }) => {
    const { property, value } = data;
    // @ts-expect-error this is synced between 2 instances of the app.
    settings[property] = value;
  };
}
export function saveConfig() {
  const originalConfig = globalSettings.read();
  objectEntries(settings).forEach(([key, value]) => {
    if (typeof value !== typeof defaultConfig[key])
      // prettier-ignore
      throw new Error(`Type mismatch for ${key} ${typeof value} !== ${typeof defaultConfig[key]}`);
    if (originalConfig[key] !== value)
      connection?.postMessage({ property: key, value });
  });
  globalSettings.write(settings);
}
